.centered {
    width: 100%;
    height: calc(100vh - 68px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.centered-horizontally {
    display: flex;
    justify-content: center;
}
